<template>
  <div
    class="flex gap-2 justify-between w-full h-[46px]"
    :class="{'md:hidden': !store.suggestion.manager?.contactsSettings?.messengers?.whatsapp?.phoneNumber}"
  >
    <flat-building
      class="md:hidden"
      :building="flat.building"
    />
    <flats-btn-book
      v-if="store.suggestion.manager?.contactsSettings?.messengers?.whatsapp?.phoneNumber"
      :class="buttonClass"
      :flat="flat"
      :mobile-phone="store.suggestion.manager.contactsSettings?.messengers.whatsapp.phoneNumber"
    />
    <flats-btn-book-viewing
      v-if="store.suggestion.manager?.contactsSettings?.messengers?.whatsapp?.phoneNumber"
      :class="buttonClass"
      :flat="flat"
      :mobile-phone="store.suggestion.manager.contactsSettings.messengers.whatsapp.phoneNumber"
    />
  </div>
</template>
<script setup lang="ts">
import FlatBuilding from '~/components/flats/flat-list-card/FlatBuilding.vue'
import FlatsBtnBook from '~/components/flats/buttons/presentation/FlatsBtnBook.vue'
import FlatsBtnBookViewing from '~/components/flats/buttons/presentation/FlatsBtnBookViewing.vue'
import type { Flat } from '~/common/types/flat/Flat'
import { useSuggestionsStore } from '~/modules/suggestions/store'

const store = useSuggestionsStore()

defineProps({
  flat: {
    type: Object as PropType<Flat>,
    required: true,
  },
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
})
</script>
